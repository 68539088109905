/**
 * Add functions for Facebook
 * */

(function (wpm, $, undefined) {

	let fBUserData

	wpm.loadFacebookPixel = function () {

		try {
			wpmDataLayer.pixels.facebook.loaded = true

			// @formatter:off
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
			// @formatter:on

			fbq("init", wpmDataLayer.pixels.facebook.pixel_id)
			fbq("track", "PageView")

		} catch (e) {
			console.error(e)
		}
	}

	wpm.getRandomEventId = function () {
		return (Math.random() + 1).toString(36).substring(2)
	}

	wpm.getFbUserData = function () {
		// We need the first one for InitiateCheckout
		// where getting the user_data from the browser is too slow
		// using wpm.getCookie(), so we cache the user_data earlier.
		// And we need the second one because the ViewContent hit happens too fast
		// after adding a variation to the cart because the function to cache
		// the user_data is too slow. But we can get the user_data using wpm.getCookie()
		// because we don't move away from the page and can wait for the browser
		// to get it.
		if (fBUserData) {
			return fBUserData
		} else {
			return wpm.getFbUserDataFromBrowser()
		}
	}

	wpm.setFbUserData = function () {
		fBUserData = wpm.getFbUserDataFromBrowser()
	}

	wpm.getFbUserDataFromBrowser = function () {
		return {
			fbp              : wpm.getCookie("_fbp"),
			fbc              : wpm.getCookie("_fbc"),
			client_user_agent: navigator.userAgent,
		}
	}

	wpm.fbViewContent = function (product) {

		try {
			if (!wpmDataLayer?.pixels?.facebook?.loaded) return

			let eventId = wpm.getRandomEventId()

			fbq("track", "ViewContent", {
				content_type: "product",
				content_name: product.name,
				// content_category: product.category,
				content_ids: product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				currency   : wpmDataLayer.shop.currency,
				value      : product.price,
			}, {
				eventID: eventId,
			})

			product["currency"] = wpmDataLayer.shop.currency

			jQuery(document).trigger("wpmFbCapiEvent", {
				event_name      : "ViewContent",
				event_id        : eventId,
				user_data       : wpm.getFbUserData(),
				product_data    : product,
				product_id      : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				event_source_url: window.location.href,
			})
		} catch (e) {
			console.error(e)
		}
	}

	wpm.facebookContentIds = function () {
		let prodIds = []

		for (const [key, item] of Object.entries(wpmDataLayer.order.items)) {

			if (wpmDataLayer?.general?.variationsOutput && 0 !== item.variation_id) {
				prodIds.push(String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type]))
			} else {
				prodIds.push(String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type]))
			}
		}

		return prodIds
	}

}(window.wpm = window.wpm || {}, jQuery));
